@import './variables';

#root ~ iframe {
  display: none;
}

.loadingPageContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

//.Polaris-Frame__ContextualSaveBar {
//  position: absolute !important;
//}

.Polaris-TopBar__Contents {
  margin-right: calc(var(--p-space-4) + env(safe-area-inset-left));
}

.appPageContainer {
  .Polaris-Page {
    @media screen and (min-width: #{$screenMd}) and (max-width: #{$screenXl}) {
      padding-inline: 2.5rem;
    }
  }

  .Polaris-Header-Title {
    img {
      width: var(--p-font-size-600);
      display: inline-block;
      vertical-align: middle;
    }

    .Polaris-Icon {
      fill: currentColor;
    }
  }

  .Polaris-Page--divider {
    padding-top: 0;
  }
}

.imageStyleToggleButtons {
  // width: 100%;
  $bg-color: #3f4eae;

  button {
    // width: 100%;
    --pc-button-color: #d7d7d7;
    --pc-button-color-hover: #b7b7b7;
    --pc-button-focused: #c9c9c9;
    --pc-button-color-active: #cdcdcd;

    &:not(.Polaris-Button--primary) {
      background-color: #fbfcfd;
    }

    .Polaris-Button__Text {
      display: flex;
    }

    img {
      height: 1.8rem;
    }
  }
}

.Polaris-Modal-Header:has(#navigateToAppTitle) {
  background-color: red;
}
