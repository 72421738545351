.tabsContainer {
  padding-block: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;

  > div {
    width: 100%;
  }
  :global(.Polaris-Tabs__Wrapper) {
    border-bottom: none;
  }
}
