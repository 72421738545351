@import '../styles/variables';

:global {
  @keyframes comeFromTop {
    from {
      height: 0;
    }
    to {
      height: 2.5rem;
    }
  }
}

.topRibbon {
  background-color: $orange;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-block: 0.5rem;
  color: white;
  font-weight: 400;

  &__container {
    overflow-y: hidden;
    transition: height 0.3s;
    animation: comeFromTop 0.3s 1;
  }

  > span {
    font-weight: var(--p-font-weight-medium);
  }
}
