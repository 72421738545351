@import '../../variables';

.container {
  margin-bottom: 2rem;
}

.calloutCardTitle {
  overflow: hidden;

  :global {
    .Polaris-Stack {
      max-width: 100%;
    }

    .Polaris-Icon {
      fill: currentColor;
    }
  }
}

.appLink {
  text-decoration: none;
  color: unset;
}

.itemContainer {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto 5.24rem;
  grid-column-gap: 1rem;
  align-items: center;

  :global(.Polaris-Thumbnail) {
    width: 48px;

    @media screen and (min-width: #{$screenLg}) {
      width: 72px;
    }
  }
}

.stackContainer :global(> .Polaris-Stack > .Polaris-Stack__Item:empty) {
  display: none;
}

.banner {
  color: white;
  background-image: linear-gradient(
      96.24deg,
      rgba(0, 0, 0, 0.7) 17.59%,
      rgba(0, 0, 0, 0) 47.72%
    ),
    url('../../assets/home-page-intro-banner.png');
  background-position: 50%;
  background-size: cover;
  padding: 1.7rem 2.3rem;

  .helloText {
    color: rgb(231, 229, 228) !important;
  }
}

.appBtnContainer button {
  width: 5.28rem;
}

.appSwitchContainer {
  margin-right: 3rem;

  @media screen and (max-width: #{$screenMd}) {
    margin-right: 1.5rem;
  }

  @media screen and (max-width: #{$screenSm}) {
    margin-right: 0;
  }
}

.appName :global(.Polaris-Heading) {
  font-size: 18px;
}

.appDescription span {
  font-size: 14px;
}

.resourceItemContainer :global(.Polaris-ResourceItem) {
  cursor: default;
}
