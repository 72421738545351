.container :global {
  .Polaris-Frame.Polaris-Frame--hasTopBar {
    position: relative;
  }

  .Polaris-Frame__TopBar,
  .Polaris-Frame__Navigation:not([aria-modal='true']) {
    position: absolute;
  }

  .Polaris-TopBar__Logo,
  .Polaris-Navigation__Logo {
    width: 12.5rem !important;
  }
}

.navigationContainer :global(.Polaris-Navigation__Text) {
  font-weight: var(--p-font-weight-regular);
}

.appNavigationSection__container :global {
  .Polaris-Navigation__ListItem {
    .Polaris-Navigation__Text {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .Polaris-Navigation__Text,
    .Polaris-Navigation__Item {
      max-width: 100%;
      overflow: hidden;
    }
  }
}

.navigationIconImg {
  filter: none !important;
}
