.changesToBeDoneLastText {
  //margin-left: 1rem;
  margin-top: 1rem;
  display: inline-block;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1rem;

  :first-child {
    white-space: nowrap;
  }

  :last-child {
    flex-grow: 1;
    height: 0.3rem;
  }
}

.stepContainer {
  display: inline-block;
  width: 100%;
  height: 30vh;
  overflow: hidden auto;
}
