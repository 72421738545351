.handle {
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
  inset-inline-start: calc(100% - 20px);

  &::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    transition: all 0.2s ease-in-out;
    content: '';
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
  vertical-align: middle;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
  background: var(--p-action-primary);

  &:not(.checked).loading {
    svg,
    path {
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }

  &.loading {
    cursor: not-allowed;
    opacity: 0.65;

    .handle {
      color: rgba(0, 0, 0, 0.88);
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &:not(.checked) {
    background: rgba(0, 0, 0, 0.25);
    cursor: pointer;

    .handle {
      color: rgba(0, 0, 0, 0.88);
      inset-inline-start: 2px;
    }
  }
}

.switchInner {
  color: rgba(0, 0, 0, 0.88);
  cursor: pointer;
  user-select: none;
  //display: block;
  overflow: hidden;
  border-radius: 100px;
  height: 100%;
}

.switchInnerChecked {
  cursor: pointer;
  user-select: none;
  display: block;
  transition: margin-inline-start 0.2s ease-in-out,
    margin-inline-end 0.2s ease-in-out;
  pointer-events: none;
  margin-inline-start: 9px;
  margin-inline-end: 24px;
}

.switchInnerUnchecked {
  cursor: pointer;
  user-select: none;
  display: block;
  transition: margin-inline-start 0.2s ease-in-out,
    margin-inline-end 0.2s ease-in-out;
  pointer-events: none;
  margin-top: -22px;
  margin-inline-start: calc(24px + 100% - 22px);
  margin-inline-end: calc(9px - 100% + 22px);
}

.loadingIcon {
  user-select: none;
  animation: loadingCircle 1s infinite linear;
  display: inline-flex;
  align-items: center;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  box-shadow: none;
  cursor: not-allowed;
  position: relative;
  top: 2px;
  vertical-align: top;

  svg {
    user-select: none;
    font-style: normal;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: var(--p-action-primary);
    width: 1em;
    height: 1em;
    fill: currentcolor;
    line-height: 1;
    display: inline-block;
    box-shadow: none;
    cursor: not-allowed;

    path {
      user-select: none;
      font-style: normal;
      text-align: center;
      text-transform: none;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}

@-webkit-keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
