.index {
  user-select: none;
  pointer-events: none;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: var(--p-action-primary);
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
}

.itemContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;

  .textContainer {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  .buttonContainer {
    align-self: center;
  }
}
