.fallbackContainer {
  min-width: 100px;
  margin-right: 1rem;
  display: inline-block;
}

.userMenu {
  cursor: pointer;

  * {
    white-space: nowrap;
    vertical-align: middle;
  }

  :global(.Polaris-Icon) {
    display: inline-block;
    vertical-align: middle;
  }
}

.topBarShopName {
  display: inline-block;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
